import Layout from "../../components/layout";
import Consumer from "../../context";
import {HeadFC} from "gatsby";
import * as React from "react";
import {Content} from "../../components/content";


interface Partner {
    name: string
    href: string
    icon_url: string
}

import Tsinghua_University_Logo from "../../img/logo/partner/Tsinghua_University_Logo.svg"
import Peking_University_seal from "../../img/logo/partner/Peking_University_seal.svg"
import Jiangnan_University_logo from "../../img/logo/partner/Jiangnan_University_logo.svg"
import UST from "../../img/logo/partner/UST.svg"
import HKBU from "../../img/logo/partner/HKBU.svg"
import LingnanUniversity_logo_2 from "../../img/logo/partner/LingnanUniversity_logo_2.svg"
import hebei_medical_university from "../../img/logo/partner/hebei_medical_university.svg"
import Hebei_Normal_University_Badge from "../../img/logo/partner/Hebei_Normal_University_Badge.svg"
import xi_an_jiaotong_university from "../../img/logo/partner/xi_an_jiaotong_university.svg"
import Zhejiang_University_Logo from "../../img/logo/partner/Zhejiang_University_Logo.svg"
import Harbin_Institute_of_Technology_logo from "../../img/logo/partner/Harbin_Institute_of_Technology_logo.svg"
import henan_university from "../../img/logo/partner/henan_university.svg"
import Nanjing_University_of_Chinese_Medicine from "../../img/logo/partner/Nanjing_University_of_Chinese_Medicine.svg"
import CategoryNav, {CategoryItem} from "../../components/category-nav";
import {SEO} from "../../components/seo";


const partner_list: Partner[] = [
    {
        name: '清华大学',
        href: '#',
        icon_url: Tsinghua_University_Logo
    },
    {
        name: '北京大学',
        href: '#',
        icon_url: Peking_University_seal
    },
    {
        name: '江南大学',
        href: '#',
        icon_url: Jiangnan_University_logo
    },
    {
        name: '香港科技大学',
        href: '#',
        icon_url: UST
    },
    {
        name: '香港浸会大学',
        href: '#',
        icon_url: HKBU
    },
    {
        name: '香港岭南大学',
        href: '#',
        icon_url: LingnanUniversity_logo_2
    },
    {
        name: '河北医科大学',
        href: '#',
        icon_url: hebei_medical_university
    },
    {
        name: '河北师范大学',
        href: '#',
        icon_url: Hebei_Normal_University_Badge
    },
    {
        name: '西安交通大学',
        href: '#',
        icon_url: xi_an_jiaotong_university
    },
    {
        name: '哈尔滨工业大学（威海）',
        href: '#',
        icon_url: Harbin_Institute_of_Technology_logo
    },
    {
        name: '南京中医药大学',
        href: '#',
        icon_url: Nanjing_University_of_Chinese_Medicine
    },
    {
        name: '河南大学',
        href: '#',
        icon_url: henan_university
    },
    {
        name: '浙江大学',
        href: '#',
        icon_url: Zhejiang_University_Logo
    },
]

const PartnershipPage = () => {


    let cats: CategoryItem[] = [
        {
            title: '合作院校',
            active: true,
            href: '/partnerships/partners'
        },
        {
            title: '官方代表',
            active: false,
            href: '/partnerships/agents'
        },
    ]

    return (
        <Layout>
            <Consumer>
                {({ data, set }) => (
                    (data.category === 'partnership') ? console.log(data.category) :  set({ category: 'partnership' })
                )}
            </Consumer>

            {/*the second menu*/}
            <div className="mt-10">
                <CategoryNav cats={cats} />
            </div>

            <div className="container max-w-7xl mx-auto">
                <div className="grid mt-8 grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {partner_list.map(item => (
                        <div className="w-full my-8 text-center">
                            <img className="object-center h-20 sm:h-28 xl:h-36 mx-auto mb-8"
                                 src={item.icon_url}
                                 alt={item.name}
                            />
                            <div className="mt-2">
                                <h3 className="text-xl font-medium">{item.name}</h3>
                                {/*<span className="mt-1 font-medium text-gray-600 dark:text-gray-300">{item.href}</span>*/}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
}

export default PartnershipPage

export const Head = () => (
    <SEO title="合作院校" description="爱尔兰高威大学的合作院校" />
)
