import React from "react"
import {Link} from "gatsby";

export interface CategoryItem {
    title: string
    href: string
    active: boolean
}

interface CategoryNavParams {
    cats: CategoryItem[]
}

export default function CategoryNav( { cats }: CategoryNavParams ) {
    return (
        <section>
            <div className="w-full max-w-7xl mx-auto">
                {/* this menu will disappear if there is only one item */}
                {(cats.length > 1) ? (
                    <>
                        <ul className="flex mb-1 list-none flex-wrap pt-3 flex-row">
                            {
                                cats.map((cat) => (
                                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                                        {/*@ts-ignore gatsbyjs/gatsby#35715 */}
                                        <Link
                                            className={
                                                "text-sm font-bold uppercase px-5 py-3 block leading-normal text-white hover:bg-magenta hover:text-white " +
                                                (cat.active
                                                    ? "bg-galway-maroon text-white"
                                                    : "bg-gray-100 text-black")
                                            }


                                            to={cat.href}
                                        >
                                            {cat.title}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="w-full h-2 bg-galway-deep-maroon"></div>
                    </>
                ) : (<></>)
                }
            </div>
        </section>
    )
}